<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Watch later</title>
    <g clip-path="url(#clip0_2285_9833)">
      <path
        d="M23.1956 4C12.717 4 4.14355 13 4.14355 24C4.14355 35 12.717 44 23.1956 44C33.6742 44 42.2476 35 42.2476 24C42.2476 13 33.6742 4 23.1956 4ZM31.1975 32.4L21.2904 26V14H24.1482V24.4L32.7216 29.8L31.1975 32.4Z"
        fill="#E9EBED"
      />
      <path
        d="M23.1956 4C12.717 4 4.14355 13 4.14355 24C4.14355 35 12.717 44 23.1956 44C33.6742 44 42.2476 35 42.2476 24C42.2476 13 33.6742 4 23.1956 4ZM31.1975 32.4L21.2904 26V14H24.1482V24.4L32.7216 29.8L31.1975 32.4Z"
        fill="url(#paint0_linear_2285_9833)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2285_9833"
        x1="266.48"
        y1="-157.818"
        x2="24.8175"
        y2="54.5523"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip0_2285_9833">
        <rect
          width="45.7249"
          height="48"
          fill="white"
          transform="translate(0.333008)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-later',
  props: {
    width: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    height() {
      return this.width - 2
    },
  },
}
</script>
